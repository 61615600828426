
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { themeLightLogo } from "@/core/helpers/config";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  computed: {
    passwordFieldType() {
      if (this.passwordVisible) {
        return "text";
      } else {
        return "password";
      }
    },
  },
  methods: {
    togglePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const passwordVisible = ref(false);
    const submitButton = ref<HTMLElement | null>(null);

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      store
        .dispatch(Actions.LOGIN, values)
        .then(function () {
          router.push({
            name: store.getters.getHomePage,
          });
        })
        .catch(() => {
          if (store.getters.getErrorCode == 412) {
            router.push({
              name: "change-password",
              params: {
                username: values.username,
                password: values.password,
              },
            });
          } else {
            Swal.fire({
              text: Object.values(store.getters.getErrors)[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Volver",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      passwordVisible,
      onSubmitLogin,
      themeLightLogo,
      submitButton,
    };
  },
});
